.carouselContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 620px;
  margin: 2rem auto 0;
  padding: 1rem;
}

.navButton {
  background: transparent !important;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.navButton:hover {
  background: var(--bg-mute) !important;
  border-radius: 50%;
}

.slide {
  text-align: center;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--bg-mute);
}

.title {
  color: var(--font-primary);
  font-size: 1.5rem;
  line-height: 1.25;
  margin-top: 12px;
  font-weight: 400;
  text-align: center;
}

.description {
  color: var(--font-secondary);
  font-size: 0.875rem;
  letter-spacing: 0.0142857143em;
  font-weight: 400;
  flex-grow: 1;
  margin-top: 12px;
  text-align: center;
}
