.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  /*flex-grow: 1;*/
  width: 100vw;
  min-height: calc(100vh - 64px);
}

.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.25rem 1.5rem 2.25rem;
  border-bottom: 1px solid var(--border);
  /*border-radius: 0.5rem;*/
}

.tabOptions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.meetingOptionsContent {
  background: var(--bg-primary);
  padding: 0 !important;
}

.meetingOption {
  cursor: pointer;
  color: var(--font-primary);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1rem;
  height: 48px;
  padding: 0 1rem;
}

.meetingOption:hover {
  background: var(--bg-mute);
}

.meetLinkHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex: 1;
}

.meetingLinkInput {
  color: var(--font-primary);
  flex: 1;
}

.joinBtn {
  background: transparent !important;
  color: var(--bg-secondary) !important;
}

.joinBtn:hover {
  background: var(--bg-secondary-xl) !important;
}

.meetLinkInputHolder {
  flex: 1;
  height: 44px;
  border: 1px solid var(--border);
  border-radius: 44px;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.dialog {
  background: var(--bg-primary);
  color: var(--font-primary);
  gap: 2rem;
  padding: 2rem;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.dialogTitle {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 400;
  max-width: 80%;
}

.dialogSubtitle {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
}

.link {
  width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary) !important;
}

.linkHolder {
  background: #dde3ea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 0.75rem;
  margin-top: 1rem;
  border-radius: 2rem;
  width: 100%;
}

.card {
  border: none !important;
  z-index: 100000;
  background: var(--bg-secondary-xl);
  color: var(--font-primary);
  padding: 1rem;
  max-width: 90%;
}
